import React, { useState } from 'react';
import styled from 'styled-components';

// Estilizando os componentes com styled-components
const DropdownButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;

  &:hover {
    background-color: #45a049;
  }
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
`;

const MenuItem = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonDropDown = () => {
  // Usando o estado para controlar a visibilidade do menu
  const [showMenu, setShowMenu] = useState(false);

  // Função para alternar a visibilidade do menu
  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleMenu}>Dropdown</DropdownButton>
      <DropdownMenu show={showMenu}>
        <MenuItem href="#option1">Option 1</MenuItem>
        <MenuItem href="#option2">Option 2</MenuItem>
        <MenuItem href="#option3">Option 3</MenuItem>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default ButtonDropDown;
