import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import styled from 'styled-components'; // Importando o styled-components

// Estilizando o Sidebar com styled-components
const StyledSidebar = styled(Sidebar)`
  height: 100vh; /* Altura da tela inteira */
  width: 250px; /* Largura do sidebar */
  position: fixed; /* Fixa o sidebar na tela */
  top: 0;
  left: 0;
  background-color: #1e2a32; /* Cor de fundo do sidebar */
`;

const StyledMenu = styled(Menu)`
  padding-top: 20px;
  height: 100%; /* Faz com que o menu ocupe toda a altura do Sidebar */
  display: flex;
  flex-direction: column; /* Organiza os itens em coluna */
  justify-content: flex-start; /* Garante que os itens fiquem no topo */
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: transparent;
  color: #d1d8e0;
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid #2f3a47; /* Linha separadora entre os itens */

  &:hover {
    background-color: #2f3a47;
    color: #f0f5f9;
  }

  &.active {
    background-color: #13395e;
    color: #b6c8d9;
  }
`;

const StyledSubMenu = styled(SubMenu)`
  background-color: #1e2a32;
  color: #d1d8e0;
  padding: 10px 20px;
  border-bottom: 1px solid #2f3a47;

  .pro-menu-item {
    background-color: transparent;
    color: #d1d8e0;

    &:hover {
      background-color: #2f3a47;
      color: #f0f5f9;
    }

    &.active {
      background-color: #13395e;
      color: #b6c8d9;
    }
  }
`;

const MenuLateral = () => {
  return (
    <StyledSidebar>
      <StyledMenu>
        <StyledMenuItem>
          <Link to="/documentation">Documentation</Link>
        </StyledMenuItem>

        <StyledSubMenu title="More Options">
          <StyledMenuItem>
            <Link to="/calendar">Calendar</Link>
          </StyledMenuItem>
          <StyledMenuItem>
            <Link to="/e-commerce">E-commerce</Link>
          </StyledMenuItem>
        </StyledSubMenu>

        <StyledMenuItem>
          <Link to="/settings">Settings</Link>
        </StyledMenuItem>
      </StyledMenu>
    </StyledSidebar>
  );
};

export default MenuLateral;
