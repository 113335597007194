import React , {useState, useEffect, useRef} from 'react'
import {withRouter} from 'react-router-dom'




const HomeV2 = (props) =>{
    return <div>teste</div>
}


export default withRouter(HomeV2)