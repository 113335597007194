import React from 'react';
import styled from 'styled-components';
import { Typography, TablePagination } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px 10px;
  width: 200px;
`;

const OrderByContainer = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px 10px;
  width: 200px;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  font-weight: bold;
`;

const Paginator = ({ registrosFiltrados, page, handleChangePage, rowsPerPage, handleChangeRowsPerPage, orderBy, orderDirection }) => {
  return (
    <Container >
      {/* Total de registros */}
      <InfoContainer>
        <StyledTypography variant='caption'>
          <strong>Total de registros:</strong> {registrosFiltrados.length}
        </StyledTypography>
      </InfoContainer>

      {/* TablePagination */}
      <PaginatorWrapper>
        <TablePagination
          component="div"
          count={registrosFiltrados.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por página'
          rowsPerPageOptions={[]}
        />
      </PaginatorWrapper>

      {/* Order by and direction */}
      <OrderByContainer>
        <StyledTypography variant='caption'>
          <strong>Order by:</strong> {orderBy}
        </StyledTypography><br />
        <StyledTypography variant='caption'>
          <strong>Direction:</strong> {orderDirection}
        </StyledTypography>
      </OrderByContainer>
    </Container>
  );
};

export default Paginator;
