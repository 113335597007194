import React from 'react';
import styled from 'styled-components';
import { ArrowUpOutline, ArrowDownOutline, MenuOutline } from 'react-ionicons';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#f0f0f0' : '#e6e6e6')};
  }
`;

const Title = styled.span`
  font-size: ${(props) => props.fontSize}px;
  text-transform: uppercase;
  padding-left: 2px;  // Reduzimos o padding para diminuir o espaço entre o ícone e o texto
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  
  &:hover {
    transform: scale(1.2);
  }
`;

const Icon = styled.div`
  color: ${(props) => (props.isActive ? '#007aff' : '#ccc')};
  margin-right: 2px; // Reduzimos a margem para aproximar o ícone do texto
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.3);
  }
`;

const RotatingArrowUp = styled(ArrowUpOutline)`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isActive && props.direcao === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const RotatingArrowDown = styled(ArrowDownOutline)`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isActive && props.direcao === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const ContainerOrderBy = ({ campo, direcao, handleOrderChange, title, help, ativo, font }) => {
  const handleClick = () => {
    handleOrderChange(campo, direcao === 'desc' ? 'asc' : 'desc');
  };

  const renderArrow = () => {
    return direcao === 'desc' ? (
      <RotatingArrowUp
        color={ativo === campo ? '#007aff' : '#ccc'}
        title={help}
        height="14px"
        width="14px"
        isActive={ativo === campo}
        direcao={direcao}
        onClick={handleClick}
        aria-label="Ordenar de forma ascendente"
      />
    ) : (
      <RotatingArrowDown
        color={ativo === campo ? '#007aff' : '#ccc'}
        title={help}
        height="14px"
        width="14px"
        isActive={ativo === campo}
        direcao={direcao}
        onClick={handleClick}
        aria-label="Ordenar de forma descendente"
      />
    );
  };

  const renderMenuIcon = () => {
    return (
      <MenuOutline
        color={ativo === campo ? '#007aff' : '#333'}
        title={help}
        height="14px"
        width="14px"
        onClick={handleClick}
        aria-label="Alternar ordem"
      />
    );
  };

  if (campo === ativo) {
    return (
      <Container isActive={campo === ativo} onClick={handleClick}>
        <IconWrapper>
          {renderArrow()}
        </IconWrapper>
        <Title fontSize={font}>{title}</Title>
      </Container>
    );
  }

  if (campo !== null) {
    return (
      <Container onClick={handleClick}>
        <IconWrapper>
          {renderMenuIcon()}
        </IconWrapper>
        <Title fontSize={font}>{title}</Title>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{ width: '10%' }}></div>
      <div style={{ width: '90%' }}>
        <Title fontSize={font}>{title}</Title>
      </div>
    </Container>
  );
};

export default ContainerOrderBy;
