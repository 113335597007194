import React, {useState, useEffect, useRef, useCallback} from 'react'
import CaretIcon from '@material-ui/icons/ArrowDropUp'
import FilterIconUp from '@material-ui/icons/ArrowDropUp'
import {Button, Typography, Grid, TextField, InputAdornment, Switch,Checkbox, Divider, Chip} from '@material-ui/core'
import './ModalFltroPapercut.css'
import { Tooltip } from '@material-ui/core'
import {getDataRepository, substituirColetaEditada, listarClientesEquipamentos, atualizarEquipamentoDesabilitado} from '../../providers/Repository'
import ClearIcon from '@material-ui/icons/Clear';
import CachedIcon from '@material-ui/icons/Cached'
import SearchIcon from '@material-ui/icons/Filter2';
import ClientIcon from '@material-ui/icons/Domain'
import PrintOutlined from '@material-ui/icons/PrintOutlined'
import ModalOpcaoRelatorio from './ModalOpcaoRelatorio'
import {RelatorioVisaoParque} from './Relatorios/RelatorioVisaoParque'
import {RelatorioVisaoSemConexao} from './Relatorios/RelatorioVisaoSemConexao'
import {RelatorioVisaoOnline} from './Relatorios/RelatorioVisaoOnline'
import {RelatorioVisaoDesativados} from './Relatorios/RelatorioVisaoDesativados'
import {RelatorioVisaoDescobertos} from './Relatorios/RelatorioVisaoDescobertos'
import {RelatorioParqueCompleto} from './Relatorios/RelatorioParqueCompleto'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from 'sweetalert2'
import BotaoMenuRelatorios from './BotaoMenuRelatorios'
import BotaoMenuDownload from './Relatorios/BotaoMenuDownload'
import ModalBotoesAcaoDesabilitados from './ModalBotoesAcaoDesabilitados'
import {withRouter} from 'react-router-dom'
import SideBarFiltros from '../../components/SideBarFiltroColetas'
import { FilterOutline } from 'react-ionicons'

//pdfMake.vfs = pdfFonts.pdfMake.vfs;


function ModalFiltroPapercutDesabilitados(props){
    const [showFilter, setShowFilter] = useState(false)
    const [marca, setMarca]= useState('')
    const [modelo, setModelo]= useState('')
    const [enderecoIP, setEnderecoIP]= useState('')
    const [dataInicial, setDataInicial]= useState('')
    const [dataFinal, setDataFinal]= useState('')
    const [numeroSerie, setNumeroSerie]= useState('')
    const [razaoSocial, setRazaoSocial]= useState('')
    const [tipoData, setTipoData]= useState('dataCadastro')
    const [registros, setRegistros]= useState([])
    const [codigoCliente, setCodigoCliente]= useState(-1)
    const [clientes, setClientes ] = useState([])
    const {colunaFiltro} = props;
    const [loading, setLoading] = useState(false)
    const [filter, setFilter]= useState(false)
    const [ativo, setAtivo]= useState(false)
    const [semConexao, setSemConexao]= useState(false)
    const [apenasDesconectados, setApenasDesconectados]= useState(false)
    const [primeiroAcesso, setPrimeiroAcesso]= useState(true)
    const [inativos, setInativos] = useState(false)
    const {Conexao} = props;
    let reportRecord = null;
    const [openSideBar, setOpenSideBar] = useState(false)
    useEffect(()=>{
        getData()
        setPrimeiroAcesso(false)
    },[])

   const resetFiltros = () =>{
            setMarca('')
            setModelo('')
            setEnderecoIP('')
            setNumeroSerie('')
            setDataFinal('')
            setDataInicial('')
            setFilter(false)
   }



    const setColetaRepositorio = (data) =>{
       
        

        substituirColetaEditada(data)
        .then(updatedData => {
            

            const temp = updatedData.filter(item => item.ativo === 'f' )
           
            setRegistros(temp)
    
            props.setRegistrosFiltrados(temp);
           
        
        })
        .catch(error => {
            console.log('Error updating data:', error);
        });
        
    }


    useEffect(()=>{
        if(!primeiroAcesso){
            getData();
        }
        
    },[ativo])

    useEffect(()=>{
        if(!primeiroAcesso){
           // getData();
        }
        if(!semConexao){
            setApenasDesconectados(false)
        }
        
    },[semConexao])
    
    useEffect(()=>{
        if(!primeiroAcesso){
         //   getData();
           
        }
        
    },[apenasDesconectados])

    
    useEffect(()=>{
        if(!primeiroAcesso){
           // getData();
           
        }
        
    },[inativos])
    


    const getData = (dataType = 'aoVivo') =>{
        setLoading(true)
        reportRecord = null;
        getDataRepository(props.chaveEmpresa, props.dataType).then(data=>{
            props.setRegistros(data);
            let temp = [];
           
            switch(dataType){
                case 'aoVivo':
                    temp = data.filter(item => item.ativo =='f' )
                    
                break;
                case 'visaoParqueCompleto':
                    setLoading(false)
                    renderRelatorio(data, 'visaoParqueCompleto')
                    return;
                    
                break;

                case 'relatorioOnline':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t')), 'onLine')
                    return;
                    
                break;
                case 'relatorioOffLine':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo =='t')), 'offLine')
                    return;
                break;
                case 'relatorioNovos':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.equipamentonovo =='t' && item.ativo =='t'), 'Relatório de equipamentos desabilitados')
                    return;
                break;

                case 'relatorioDesabilitados':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='f'), 'inativos')
                    return;
                break;

                case 'relatorioDescobertas':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='t' && item.equipamentonovo =='t'),'descobertas' )
                    return;
                break;

                case 'relatorioOnline':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t')), 'onLine')
                    return;
                    
                break;
                case 'relatorioOffLine':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo =='t')), 'offLine')
                    return;
                break;
                case 'relatorioNovos':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.equipamentonovo =='t' && item.ativo =='t'), 'Relatório de equipamentos desabilitados')
                    return;
                break;

                case 'relatorioDesabilitados':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='f'), 'inativos')
                    return;
                break;

                case 'relatorioDescobertas':
                    setLoading(false)
                    renderRelatorio(data.filter(item => item.ativo =='t' && item.equipamentonovo =='t'),'descobertas' )
                    return;
                break;
                //----

                case 'relatorioOnline2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.dias == true && (item.equipamentonovo =='f' && item.ativo =='t')), 'onLine')
                    return;
                    
                break;
                case 'relatorioOffLine2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.dias == false && (item.equipamentonovo =='f' && item.ativo =='t')), 'offLine')
                    return;
                break;
                case 'relatorioNovos2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.equipamentonovo =='t' && item.ativo =='t'), 'Relatório de equipamentos desabilitados')
                    return;
                break;

                case 'relatorioDesabilitados2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.ativo =='f'), 'inativos')
                    return;
                break;

                case 'relatorioDescobertas2':
                    setLoading(false)
                    exportToCsv(data.filter(item => item.ativo =='t' && item.equipamentonovo =='t'),'relatorioDescobertas2' )
                    return;
                break;

                case 'visaoParqueCompleto2':
                    setLoading(false)
                    exportToCsv(data,'visaoParqueCompleto2' )
                    return;
                break;



                

                case 'todas':
                    setLoading(false)                    
                break;
            }
            
            
            setRegistros(temp)
    
            props.setRegistrosFiltrados(codigoCliente > -1 ? temp.filter(item=> item.idcliente == codigoCliente) : temp);
           
            const clientesLista = listarClientesEquipamentos(temp);
            
            setClientes(clientesLista);



            setTimeout(()=>{
                setLoading(false)
            },1500)
        }).catch(error=>{

            console.log('Erro repository ' + error)

            setLoading(false)
        })
        
       
        
       
    }


    const searchEquipmento = (pEndereco, pDataInicial, pdataFinal, pMarca, pModelo, pNumeroSerie, pRazaoSocial) => {
        if (!registros) {
            return;
        }
        setLoading(true);
    
        let dataTemp = codigoCliente != '-1' ? registros.filter(item => item.idcliente == codigoCliente) : registros;
    
        const temp = dataTemp.filter(equipamento => {
            const equipamentoEnderecoIp = (equipamento.enderecoip || "").toLowerCase();
            const equipamentoDataCadastro = (equipamento.datacadastro || "").toLowerCase();
            const equipamentoDataColeta = (equipamento.datacoleta || "").toLowerCase();
            const equipamentoMarca = (typeof equipamento.marca === "string" ? equipamento.marca.toLowerCase() : "");
            const equipamentoModelo = (typeof equipamento.modelo === "string" ? equipamento.modelo.toLowerCase() : "");
            const equipamentoNumeroSerie = (typeof equipamento.numeroserie === "string" ? equipamento.numeroserie.toLowerCase() : "");
            const equipamentoRazaoSocial = (typeof equipamento.razaosocial === "string" ? equipamento.razaosocial.toLowerCase() : "");
            
            const searchEnderecoIp = (pEndereco || "").toLowerCase();
            const searchDataCadastro = (pDataInicial || "").toLowerCase();
            const searchDataColeta = (pdataFinal || "").toLowerCase();
            const searchMarca = (pMarca || "").toLowerCase();
            const searchModelo = (pModelo || "").toLowerCase();
            const searchNumeroSerie = (pNumeroSerie || "").toLowerCase();
            const searchRazaoSocial = (pRazaoSocial || "").toLowerCase();
            
            const dataCadastroMatch = !searchDataCadastro ||
                (new Date(equipamentoDataCadastro) >= new Date(searchDataCadastro) &&
                 new Date(equipamentoDataCadastro) <= new Date(pDataInicial));
    
            const dataColetaMatch = !searchDataColeta ||
                (new Date(equipamentoDataColeta) >= new Date(searchDataColeta) &&
                 new Date(equipamentoDataColeta) <= new Date(pdataFinal));
    
            return (
                equipamentoEnderecoIp.includes(searchEnderecoIp) &&       
                dataCadastroMatch &&
                equipamentoMarca.includes(searchMarca) &&
                equipamentoModelo.includes(searchModelo) &&
                dataColetaMatch &&
                equipamentoNumeroSerie.includes(searchNumeroSerie) &&
                equipamentoRazaoSocial.includes(searchRazaoSocial)
            );
        });
    
        props.setRegistrosFiltrados(temp);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };




    const getFilterData = (value, option = '') =>{
      
        const palavraChave = value
        if(registros.length == 0){
            return;
        }
        setLoading(true)
        props.setRegistrosFiltrados([])
        let tempData = null
        if(option == 'codigoCliente'){
            if(value == '-1'){
                props.setRegistrosFiltrados(registros);
                setTimeout(()=>{
                    setLoading(false)
                },500)
                return
            }
       
            tempData = registros.filter(function(equipamento) {
                return equipamento.idcliente == value;
            })
            props.setRegistrosFiltrados(tempData);
            setTimeout(()=>{
                setLoading(false)
            },500)
           return;
        }

        

            
        
    }

   

    const renderRelatorio = (reportData, layout ='default') =>{
       
        if(!reportData || reportData.length == 0){
            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Não existem informações para exibição',
                icon:'warning', 
                showConfirmButton:false, 
                showCancelButton:false, 
                timer : 1500
            })
            return
        }
        try{

            Swal.fire({
                title : 'Datacount Collector', 
                text : 'Aguarde...carregando documento...',
                icon:'info', 
                showConfirmButton:false, 
                showCancelButton:false
            })

            if(codigoCliente > -1){
                //props.setRegistrosFiltrados(codigoCliente > -1 ? temp.filter(item=> item.idcliente == codigoCliente) : temp);
                if(reportData && reportData.length > 0){
                    reportData = reportData.filter(item=> item.idcliente == codigoCliente)
                }                
            }

            switch(layout){
                case 'default':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const classeImpressao = new RelatorioVisaoParque(parametrosImpressao) 
        
                        classeImpressao.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParque.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;
                case 'onLine':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoConectados = new RelatorioVisaoOnline(parametrosImpressao) 
        
                        visaoConectados.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'offLine':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoSemConexao = new RelatorioVisaoSemConexao(parametrosImpressao) 
        
                        visaoSemConexao.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'inativos':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoInativos = new RelatorioVisaoDesativados(parametrosImpressao) 
        
                        visaoInativos.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'descobertas':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const visaoDescobertas = new RelatorioVisaoDescobertos(parametrosImpressao) 
        
                        visaoDescobertas.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioSemConexao.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;

                case 'visaoParqueCompleto':
                    setTimeout(() => {
                        const parametrosImpressao ={
                            registros : reportData, 
                            title : codigoCliente > 0 ? reportData[0].razaosocial : 'Todos os clientes'
                        }
        
                        const parqueCompleto = new RelatorioParqueCompleto(parametrosImpressao) 
        
                        parqueCompleto.PreparaDocumento().then(documento=>{                    
                            pdfMake.createPdf(documento).download("relatorioParqueCompleto.pdf")
                        }).catch(error=>{
            
                        })
                            
                       Swal.close()

                    }, (1000));
                break;



                
            }
            

            }catch(e){
                console.log(e)
                Swal.close()      
            }

    }


        //https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react
   const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = (usersData) => {
   
    downloadFile({
      data: JSON.stringify(usersData),
      fileName: 'relatorioColetas.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = (userData, tipo = '') => {
    if(!userData || userData.length == 0){
        Swal.fire({
            title : 'Datacount Collector', 
            text : 'Não existem informações para exportação',
            icon:'warning', 
            showConfirmButton:false, 
            showCancelButton:false, 
            timer : 1500
        })
        return;
    }
   
    if(tipo == 'relatorioDescobertas2'){
        let headers = ['Endereco IP ,Data Cadastro, Hora Cadastro, Razao Social, Marca, Modelo, Numero Serie, ID Dca']
  
        // Convert users data to a csv
        let usersCsv = userData.reduce((acc, user) => {
          const { enderecoip, datacadastro, horacadastro, razaosocial, marca, modelo, numeroserie, iddca} = user
          acc.push([enderecoip, datacadastro, horacadastro.substr(0,8),razaosocial,  marca, modelo, numeroserie,iddca].join(','))
          return acc
        }, [])
      
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'users.csv',
          fileType: 'text/csv',
        })
    }else if(tipo == 'visaoParqueCompleto2'){
        let headers = ['Endereco IP ,Data Cadastro, Hora Cadastro,Data Coleta, Hora Coleta, Razao Social, Marca, Modelo, Numero Serie, ID Dca, Status Coleta']
  
        // Convert users data to a csv
        let usersCsv = userData.reduce((acc, user) => {
            const { enderecoip, datacadastro, horacadastro, datacoleta, horacoleta, razaosocial, marca, modelo, numeroserie, iddca, ativo} = user
            acc.push([enderecoip, datacadastro, horacadastro.substr(0,8),  datacoleta.substr(0,8), horacoleta, razaosocial,  marca, modelo, numeroserie,iddca, ativo == 't' ? 'ATIVO' : 'DESABILITADO'].join(','))
          return acc
        }, [])
      
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'relatorioColetas.csv',
          fileType: 'text/csv',
        })
    }else{
        let headers = ['Endereco IP ,Data Coleta, Hora Coleta, Razao Social, Numero de Serie, Marca, Modelo, A4 Mono, A3 Mono, Total Mono, A4 Color, A3 Color, Total Color, Total Geral, Scan 1, Scan 2']
  
        // Convert users data to a csv
        let usersCsv = userData.reduce((acc, user) => {
          const { enderecoip, datacoleta, horacoleta, razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal, contadormonoscanner, contadorcolorscanner, contadortotalscanner} = user
          acc.push([enderecoip, datacoleta, horacoleta,razaosocial, numeroserie, marca, modelo, contadora4mono, contadora3mono, monototal, contadora4color, contadora3color, colortotal, contadortotal, contadormonoscanner, contadorcolorscanner, contadortotalscanner].join(','))
          return acc
        }, [])
      
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: 'relatorioColetas.csv',
          fileType: 'text/csv',
        })
    }
  
    // Headers for each column
    
  }




    
    return <div style ={{marginTop : '-60px'}}>
        <br/>
   
        
        <Grid container spacing ={1} style ={{marginTop : '10px'}}>
           
        <Grid item xs ={1}>
                <Tooltip title='Barra de pesquisa'>
                    <Button  onClick={()=>setOpenSideBar(!openSideBar)} variant='outlined' style={{backgroundColor : '#fff'}}>
                        <FilterOutline
                            color={'#00000'} 
                            height="24px"
                            width="24px"
                            />    
                            <Typography variant='caption'>Filtro</Typography>
                    </Button> 
                </Tooltip> 
            </Grid>
            <Grid item xs={7}>
                <TextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    placeholder='Pesquisa rápida'
                    InputLabelProps={{shrink:true}}
                    disabled={razaoSocial !== ''}
                    select
                    value={codigoCliente}
                    className='textField-busca'
                    SelectProps={{
                        native : true
                    }}
                    InputProps={{
                        startAdornment : <InputAdornment position='start'>
                            <ClientIcon style ={{color:'#90A4AE'}}/>
                        </InputAdornment>
                    }}
                    onChange={(e)=>{
                        setCodigoCliente(e.target.value)
                        getFilterData(e.target.value, 'codigoCliente')                      
                        
                    }}
                    >
                        <option value ='-1'>{razaoSocial === '' ? 'Selecione um cliente para pesquisar...' : razaoSocial.toLocaleUpperCase()}</option>
                        {
                            clientes && clientes.map(function(cliente, i){
                                return <option value ={cliente.idcliente}>{cliente.razaosocial}</option>
                            })
                        }
                        
                </TextField>
            </Grid>
            
            <Grid item xs={1} style ={{display :'flex'}}>
                    <Tooltip title='Limpar todas as opções de filtro'>
                        <ClearIcon style = {{color:'#546E7A', marginLeft:'5px', marginTop :'2px',fontSize :'32px', cursor:'pointer'}} className='icone-warning' onClick={()=>{
                           
                            setCodigoCliente(-1)
                            props.setRegistrosFiltrados(registros)
                            
                            resetFiltros()
                        }}/>
                    </Tooltip>
                    <Tooltip title = 'Limpar filtro e buscar dados atualizados'>
                        <CachedIcon style = {{color:'#546E7A', marginLeft:'5px', marginTop :'2px',fontSize :'32px', cursor:'pointer'}} className={loading ? 'loading-spinner icone-success' : 'icone-success'} onClick={()=>{
                            //setCliente([]);
                            setCodigoCliente(-1)
                            getData();
                            resetFiltros()
                        }}/>
                    </Tooltip>
            </Grid>
            <Grid item xs={1} style ={{display :'flex',  justifyContent:'flex-end', paddingRight :'8px'}}>
                <ModalBotoesAcaoDesabilitados 

                        habilitarMonitoramento ={()=>{
                            if(props.usuario.equipamentosativacao === 'f' && props.usuario.idusuariorevenda !== null){
                                Swal.fire({
                                    title :'Datacount', 
                                    text : 'Você não term permissão de acessar este recurso!', 
                                    icon :'warning', 
                                    showConfirmButton:false, 
                                    showCancelButton : false, 
                                    timer : 2500
                                })
                                return
                            }
                            Swal.fire({
                                title : 'Datacount', 
                                text : 'Deseja Reativar o monitoramento para o(s) equipamento(s)?', 
                                icon : 'question', 
                                showConfirmButton : true, 
                                confirmButtonText : 'Sim', 
                                showCancelButton : true, 
                                cancelButtonText : 'Não'
                            }).then(resposta=>{
                                if(resposta.isConfirmed){
                                    setLoading(true)
                                    const request = props.registrosFiltrados.filter(item=> item.selecionado == 't').map((item)=>{
                                        return item.idcoleta
                                    })
                                   
                                    Conexao.post('coletasController/habilitarListaMonitoramento?idempresa',{
                                        listaColetas :request
                                    }).then(response=>{
                                        setLoading(false)
                                        if(response.data.status =='OK'){
                                            atualizarEquipamentoDesabilitado(request,'reativarEquipamento', 't').then(response=>{

                                                if(response && response.length > 0){

                                                    setRegistros(response)
    
                                                    props.setRegistrosFiltrados(codigoCliente > -1 ? response.filter(item=> item.idcliente == codigoCliente) : response);
                                                    
                                                    const clientesLista = listarClientesEquipamentos(response);
                                                        
                                                    setClientes(clientesLista);

                                                    Swal.fire({
                                                        title : 'Datacount', 
                                                        text:'Operação realizada com sucesso !', 
                                                        showCancelButton : false, 
                                                        showConfirmButton : false, 
                                                        timer  : 2500, 
                                                        icon : 'success'
                                                    })

                                                } else{

                                                    Swal.fire({
                                                        title : 'Datacount', 
                                                        text:'Operação realizada com sucesso ! Você será redirecionado agora...', 
                                                        showCancelButton : false, 
                                                        showConfirmButton : false, 
                                                        timer  : 1500, 
                                                        icon : 'success',
                                                        onClose : ()=>{
                                                            props.history.push({pathname : '/gestao_contadores/revenda/'+props.codigoRevenda+'/empresa/'+props.chaveEmpresa, state :{
                                                                empresa : props.location.state.empresa,
                                                                usuario : props.location.state.usuario, 
                                                            }})
                                                        }
                                                    })
                                                   
                                                }   

                                                setLoading(false)

                                            }).catch(error=>{

                                                setLoading(false)

                                            })
                                        
                                            
                                        }else{
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text:'Operação não realizada', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer  : 1500, 
                                                icon : 'warning'
                                            })
                                        }
                                        setLoading(false)
                                    }).catch(error=>{
                                        setLoading(false)
                                        Swal.fire({
                                            title : 'Datacount', 
                                            text:'Ocorreu um erro inesperado !', 
                                            showCancelButton : false, 
                                            showConfirmButton : false, 
                                            timer  : 1500, 
                                            icon : 'error'
                                        })
                                    })
                                    
                                }
                            })
                        }}

                        removerDefinitivamente = {()=>{
                            if(props.usuario.equipamentosdesativacao === 'f' && props.usuario.idusuariorevenda !== null){
                                Swal.fire({
                                    title :'Datacount', 
                                    text : 'Você não term permissão de acessar este recurso!', 
                                    icon :'warning', 
                                    showConfirmButton:false, 
                                    showCancelButton : false, 
                                    timer : 2500
                                })
                                return
                            }
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text : 'Deseja Desativar permanentemente este(s) esquipamento(s)?', 
                                        icon : 'question', 
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Sim', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Não'
                                    }).then(resposta=>{
                                        if(resposta.isConfirmed){
                                            setLoading(true)

                                            const request = props.registrosFiltrados.filter(item=> item.selecionado == 't').map((item)=>{
                                                return item.idcoleta
                                            })                                        


                                            Conexao.post('coletasController/desativarPermanenteListaColeta?idempresa',{
                                                listaColetas :  request
                                            }).then(response=>{
                                                setLoading(false)
                                                if(response.data.status =='OK'){
                                                    atualizarEquipamentoDesabilitado(request,'reativarEquipamento', 't').then(response=>{

                                                        if(response && response.length > 0){
        
                                                            setRegistros(response)
            
                                                            props.setRegistrosFiltrados(codigoCliente > -1 ? response.filter(item=> item.idcliente == codigoCliente) : response);
                                                            
                                                            const clientesLista = listarClientesEquipamentos(response);
                                                                
                                                            setClientes(clientesLista);
        
                                                            Swal.fire({
                                                                title : 'Datacount', 
                                                                text:'Operação realizada com sucesso !', 
                                                                showCancelButton : false, 
                                                                showConfirmButton : false, 
                                                                timer  : 2500, 
                                                                icon : 'success'
                                                            })
        
                                                        } else{
        
                                                            Swal.fire({
                                                                title : 'Datacount', 
                                                                text:'Operação realizada com sucesso ! Você será redirecionado agora...', 
                                                                showCancelButton : false, 
                                                                showConfirmButton : false, 
                                                                timer  : 1500, 
                                                                icon : 'success',
                                                                onClose : ()=>{
                                                                    props.history.push({pathname : '/gestao_contadores/revenda/'+props.codigoRevenda+'/empresa/'+props.chaveEmpresa, state :{
                                                                        empresa : props.location.state.empresa,
                                                                        usuario : props.location.state.usuario, 
                                                                    }})
                                                                }
                                                            })
                                                           
                                                        }   
        
                                                      
        
                                                    }).catch(error=>{
        
                                                    
        
                                                    })
                                              
                                                }else{
                                                    Swal.fire({
                                                        title : 'Datacount', 
                                                        text:'Operação não realizada', 
                                                        showCancelButton : false, 
                                                        showConfirmButton : false, 
                                                        timer  : 1500, 
                                                        icon : 'warning'
                                                    })
                                                }
                                                setLoading(false)
                                            }).catch(error=>{
                                                setLoading(false)
                                                Swal.fire({
                                                    title : 'Datacount', 
                                                    text:'Ocorreu um erro inesperado !', 
                                                    showCancelButton : false, 
                                                    showConfirmButton : false, 
                                                    timer  : 1500, 
                                                    icon : 'error'
                                                })
                                            })
                                            setLoading(false)
                                        }
                                    })
                                }}
                
                />
            </Grid>
            
            <Grid item xs={2} style ={{display :'flex',  justifyContent:'flex-end', paddingRight :'8px'}}>
                <Tooltip title ='Gerar relatório com base nos dados exibidos'>
                    <BotaoMenuRelatorios
                        relatorioVisaoAtual = {()=>renderRelatorio(props.registrosFiltrados)}
                        
                        relatorioOnline = {()=>{
                           getData('relatorioOnline');                                                                                
                        }}

                        relatorioOffLine = {()=>getData('relatorioOffLine')}
                        relatorioNovos = {()=>getData('relatorioNovos')}
                        relatorioDesabilitados = {()=>getData('relatorioDesabilitados')}
                        relatorioDescobertas = {()=>getData('relatorioDescobertas')}
                        visaoParqueCompleto = {()=>getData('visaoParqueCompleto')}
                        disabled ={props.usuario.relatorios === 'f' && props.usuario.idusuariorevenda !== null}
                        />
                    
                </Tooltip>

                <Tooltip title ='Opções de exportação de dados'>
                    <BotaoMenuDownload 
                        relatorioVisaoAtual = {()=>exportToCsv(props.registrosFiltrados)}
                        
                        relatorioOnline = {()=>{
                           getData('relatorioOnline2');                                                                                
                        }}
                        relatorioOffLine = {()=>getData('relatorioOffLine2')}
                        relatorioNovos = {()=>getData('relatorioNovos2')}
                        relatorioDesabilitados = {()=>getData('relatorioDesabilitados2')}
                        relatorioDescobertas = {()=>getData('relatorioDescobertas2')}
                        visaoParqueCompleto = {()=>getData('visaoParqueCompleto2')}
                        disabled ={props.usuario.relatorios === 'f' && props.usuario.idusuariorevenda !== null}
                        />
                    
                </Tooltip>
                
            </Grid>
            

            
            
        </Grid>
        {showFilter ? <div id='div-filter-papercut' className={showFilter ?'fadeIn' : 'fadeOut'}>
                    
                    <Grid container spacing={1}>
                        <Grid item xs ={4} style={{textAlign:'center'}}>
                            <button  size='small' color='secondary' fullWidth className='btn-limpar' onClick={()=>{
                             
                                setShowFilter(false)
                                setMarca('')
                                setModelo('')
                                setEnderecoIP('')
                                setNumeroSerie('')
                                setDataFinal('')
                                setDataInicial('')
                                setFilter(false)
                                setRazaoSocial('')
                                searchEquipmento('', '', '', '', '', '', '');
                            }}>
                                <Typography variant='caption'>Limpar</Typography>
                            </button>
                        </Grid>
                        <Grid item xs ={4} style={{textAlign:'center'}}><Typography variant='subtitle2'>Filtros</Typography></Grid>
                        <Grid item xs ={4} style={{textAlign:'center'}}>
                            <button  size='small' color='primary' fullWidth className='btn-filtrar' onClick={()=>{
                                setFilter(true)
                                searchEquipmento(enderecoIP, dataInicial, dataFinal, marca, modelo, numeroSerie, razaoSocial);
                                // searchEquipmento = {(pEndereco, pDataInicial, pdataFinal,  pMarca, pModelo, pNumeroSerie)
                                setShowFilter(false)
                               
                            }}>
                                <Typography variant='caption' >Aplicar Filtro</Typography>
                            </button>
                        </Grid>
                    </Grid>
                    

                        <br/>
                    <Grid container spacing={1} style ={{marginTop :'2px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>Marca</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                value = {marca}
                                onChange={(e)=>{
                                    setMarca(e.target.value)
                                }}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style ={{marginTop :'6px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>Modelo</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                value = {modelo}
                                onChange={(e)=>{
                                    setModelo(e.target.value)
                                }}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style ={{marginTop :'6px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>Serial</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                value={numeroSerie}
                                onChange={(e)=>setNumeroSerie(e.target.value)}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                    </Grid>

                    { codigoCliente === -1 ? <Grid container spacing={1} style ={{marginTop :'6px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>Cliente</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                disabled ={codigoCliente > -1}
                                variant='outlined'
                                fullWidth
                                value={razaoSocial}
                                onChange={(e)=>setRazaoSocial(e.target.value)}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                    </Grid> : null }

                    <Grid container spacing={1} style ={{marginTop :'6px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>Endereço IP</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                value = {enderecoIP}
                                onChange={(e)=>{
                                    setEnderecoIP(e.target.value)
                                }}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                    </Grid>



                    <Grid container spacing={1} style ={{marginTop :'6px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>1º Coleta</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                type='date'
                                value = {dataInicial}
                                onChange={(e)=>{
                                    setDataInicial(e.target.value)
                                }}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                        
                    </Grid>

                    <Grid container spacing={1} style ={{marginTop :'6px'}}>
                        <Grid item xs = {3}>
                            <Typography variant='caption'>Leitura</Typography>
                        </Grid>
                        <Grid item xs ={9}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                type='date'
                                value = {dataFinal}
                                onChange={(e)=>{
                                    setDataFinal(e.target.value)
                                }}
                                InputLabelProps={{shrink:true}}
                                size='small'/>
                        </Grid>
                        
                    </Grid>

                    
                </div> : <React.Fragment></React.Fragment>}
        <div style={{paddingTop :'10px'}}>
            <Typography variant='caption' ><strong style={{color:'#444!important'}}>Filtro: </strong></Typography>
            {
               filter && <React.Fragment>
                    {razaoSocial ? <Chip style ={{marginLeft :'2px'}} label={'Cliente: '+razaoSocial.toUpperCase()} onDelete={()=>{
                        setRazaoSocial('')
                        searchEquipmento(enderecoIP, dataInicial, dataFinal, marca, modelo, numeroSerie, '');
                        if((marca + modelo  + enderecoIP + numeroSerie + dataInicial + dataFinal) === ''  ){
                            setFilter(false)
                        }
                        }} /> :null }

                    {marca ? <Chip style ={{marginLeft :'2px'}} label={'Marca: '+marca.toUpperCase()} onDelete={()=>{
                        setMarca('')
                        searchEquipmento(enderecoIP, dataInicial, dataFinal, '', modelo, numeroSerie, razaoSocial);
                        if((modelo +razaoSocial + enderecoIP + numeroSerie + dataInicial + dataFinal) === ''  ){
                            setFilter(false)
                        }
                        }} /> :null }
                    {modelo ? <Chip style ={{marginLeft :'2px'}} label={'Modelo: '+modelo.toUpperCase()} onDelete={()=>{
                        setModelo('')
                        searchEquipmento(enderecoIP, dataInicial, dataFinal, marca, '', numeroSerie, razaoSocial);
                        if((marca  +razaoSocial + enderecoIP + numeroSerie + dataInicial + dataFinal) === ''  ){
                            setFilter(false)
                        }
                        }} /> :null }

                    {numeroSerie ? <Chip style ={{marginLeft :'2px'}} label={'Serial: '+numeroSerie.toUpperCase()} onDelete={()=>{
                        setNumeroSerie('')
                        searchEquipmento(enderecoIP, dataInicial, dataFinal, marca, modelo, '', razaoSocial);
                        if((marca  +razaoSocial + enderecoIP  + modelo + dataInicial + dataFinal) === ''  ){
                            setFilter(false)
                        }
                        }} /> :null }

                    {enderecoIP ? <Chip style ={{marginLeft :'2px'}} label={'Endereco IP: '+enderecoIP.toUpperCase()} onDelete={()=>{
                        setNumeroSerie('')
                        searchEquipmento('', dataInicial, dataFinal, marca, modelo, numeroSerie, razaoSocial);
                        if((marca  +razaoSocial + numeroSerie  + modelo + dataInicial + dataFinal) === ''  ){
                            setFilter(false)
                        }
                        }} /> :null }
                    {dataInicial ? <Chip style ={{marginLeft :'2px'}} label={'1º Coleta: '+dataInicial.toUpperCase()} onDelete={()=>{
                        setNumeroSerie('')
                        searchEquipmento(enderecoIP, '', dataFinal, marca, modelo, numeroSerie, razaoSocial);
                        if((marca  +razaoSocial + numeroSerie  + modelo + enderecoIP + dataFinal) === ''  ){
                            setFilter(false)
                        }
                        }} /> :null }
            </React.Fragment>
            }
        </div>
        <SideBarFiltros                                
                marca={marca}
                setMarca={(value)=>setMarca(value)}
                modelo={modelo}
                setModelo={(value)=>setModelo(value)}
                numeroSerie={numeroSerie}
                setNumeroSerie={(value)=>setNumeroSerie(value)}
                razaoSocial={razaoSocial}
                setRazaoSocial={(value)=>setRazaoSocial(value)}
                enderecoIP={enderecoIP}
                setEnderecoIP={(value)=>setEnderecoIP(value)}
                dataInicial={dataInicial}
                setDataInicial={(value)=>setDataInicial(value)}
                dataFinal={dataFinal}
                setDataFinal={(value)=>setDataFinal(value)}
                inativos={inativos}
                setInativos={(value)=>{
                    setInativos(value)
                    
                }}
                openSideBar= {openSideBar} setOpenSidebar={()=>setOpenSideBar(!openSideBar)}
                applyFilter={()=>{
                    setFilter(true)
                    searchEquipmento(enderecoIP, dataInicial, dataFinal, marca, modelo, numeroSerie, razaoSocial);
                    setShowFilter(false)
                }}
                resetFilter ={()=>{
                    setShowFilter(false)
                    setMarca('')
                    setModelo('')
                    setEnderecoIP('')
                    setNumeroSerie('')
                    setDataFinal('')
                    setDataInicial('')
                    setFilter(false)
                    searchEquipmento('', '', '', '', '', '', '');
                    setRazaoSocial('')
                }}
                />     
                
                
            </div>
}

export default withRouter(ModalFiltroPapercutDesabilitados)