import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'

import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TablePagination from '@material-ui/core/TablePagination';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ModalNovoParametros from '../ParametrosDCA/ModalNovoParametro';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalCliente from './ModalClientesHomologar'
import ModalListaUploads from './ModalListaUpload'

const CadastroHomologacao = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [homologacoes, setHomologacoes] = useState([])
    const [modelo, setModelo]= useState('')
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refTipoData = React.createRef();
    const refData = React.createRef();
    const refDescricao = React.createRef();

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getHomologacoes()
    }, [])

    useEffect(()=>{
        if(openModal){
           
        }
    }, [openModal])


    const getHomologacoes = () =>{
        setLoading(true)
        Conexao.post('dcaController/getHomologacoesDca', {
            idusuario : props.match.params.idempresa, 
            idempresa : props.match.params.chaveEmpresa, 
            tipoData : refTipoData.current ? refTipoData.current.value : '', 
            data : refData.current ? refData.current.value : '', 
            descricao : refDescricao.current ? refDescricao.current.value : ''
        }).then( response =>{
            if(response.data.status == 'OK'){
                setHomologacoes(response.data.payload)
            }else{
                setHomologacoes([])
            }
            setLoading(false)
        }).catch( error =>{
            setHomologacoes([])
            setLoading(false)
        });
    }


   


    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Homologação de Modelos (Lista de Pendências)
            </Link>                
        </Breadcrumbs>
    }>
      
        <Grid container spacing = {2}>
            <Grid item xs = {7}></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage='Linhas por página'
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Data para a Pesquisa'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef={refTipoData}
                    >
                    <option value = 'F'></option>
                    <option>INCLUSÃO</option>
                    <option>CONCLUSÃO</option>                    
                    </TextField>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refData}     
                   />
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Descrição do Modelo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    inputRef={refDescricao}             
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{getHomologacoes()}}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                   refTipoData.current.value = '';
                   refData.current.value ='';
                   refDescricao.current.value = '';
                }}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
       


        <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  className='table table-bordered table-striped table-hover '>
                <TableHead>
                 
                    <TableCell align='left' style = {{width:"30%"}}>Nome do Modelo</TableCell>
                    <TableCell align='center' style = {{width:"15%"}}>Data Inclusão</TableCell>
                    <TableCell align='center' style = {{width:"15%"}}>Hora Inclusão</TableCell>                    
                    <TableCell align='center' style = {{width:"5%"}}>Requisições</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Uploads</TableCell>
                    <TableCell align='center' style = {{width:"15%"}}>Data Conclusão</TableCell>
                    <TableCell align='center' style = {{width:"15%"}}>Hora Conclusão</TableCell>
                </TableHead>
                <TableBody>
                   {
                       homologacoes && homologacoes.map(function(homologacao, i){
                           return <TableRow>
                 
                           <TableCell align='left' >
                               
                               {!homologacao.datasolucao ?  <Typography className= 'span-link' onClick = {()=>{
                                    setModelo(homologacao.descricao)}}>{homologacao.descricao}</Typography> : <Typography >{homologacao.descricao}</Typography>}
                           </TableCell>
                           <TableCell align='center' >
                                <Typography>{homologacao.datacadastro}</Typography>
                           </TableCell>
                           <TableCell align='center' >
                           <Typography>{homologacao.horacadastro.substr(0,8)}</Typography>
                           </TableCell>
                           <TableCell align='center' ><ModalCliente modelo={homologacao.descricao} clientes ={homologacao.solicitacoes}/></TableCell>
                           <TableCell align='center' ><ModalListaUploads modelo={homologacao.descricao} uploads ={homologacao.uploads}/></TableCell>
                           <TableCell align='center' >{homologacao.datasolucao}</TableCell>
                           <TableCell align='center' >{homologacao.horasolucao ? homologacao.horasolucao.substr(0,8):''}</TableCell>
                           
                       </TableRow>
                       })
                   }
                  
                </TableBody>
            </Table>
        </Grid>  
        </div>                   
       
        <div className='fabIncluir'>
        <ModalNovoParametros botaoAtivo={false} nomeModelo={modelo} closeModal = {()=>{
            setModelo('')
            getHomologacoes();
            }}/>
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroHomologacao)