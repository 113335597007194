import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import styled, { keyframes } from 'styled-components';

// Animação de shake para o ícone de alerta
const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(5px); }
`;

// Componentes estilizados
const ProgressBarContainer = styled.div`
  width: 100%;
  padding: 1px;
  background-color: #fff;
  height: 13px;
  position: relative;
  display: flex;
  transition: all 0.3s ease;
  margin-top: 1px;
  border: solid 0.5px #f5f5f5;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  align-items: center;
`;

const ProgressBarFiller = styled.div`
  height: 60%;
  background-color: ${(props) => props.color || '#4caf50'};
  border-radius: 0px 5px 5px 0px;
  transition: width 0.2s ease-in-out;
  margin-left: 1px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const TonerLabelLateral = styled(Typography)`
  font-size: 8px;
  color: #333;
  margin-left: 5px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const LabelInsponivel = styled(Typography)`
  font-size: 8px;
  color: #333;
  margin-left: 5px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const TonerLabelRotulo = styled(Typography)`
  font-size: 9px;
  color: #fff;
  min-width: 12px;
  height: 12px;
  background-color: ${(props) => props.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const AlertIcon = styled(WarningIcon)`
  font-size: 12px;
  color: #ff8f00;
  cursor: pointer;
  transition: color 0.3s ease;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  &:hover {
    color: #e65100;
  }
  &.shake {
    animation: ${shake} 0.5s ease-in-out;
  }
`;

// Componente principal
const DisplayLinearToner = (props) => {
  const {
    value,
    rotulo,
    color,
    alerta,
    nivelAlerta,
    small,
    disabled,
    usarCoresPadrao
  } = props;

  // Valor a ser exibido, se estiver dentro do intervalo de 0 a 100
  const displayValue = value >= 0 && value <= 100 ? `${value}%` : 'n/d';
  
  const colors = {
    ciano: '#03A9F4',
    magenta: '#F44336',
    amarelo: '#FFC107',
    preto: '#000',
  };

  // Renderiza a cor do rótulo baseado no 'rotulo'
  const renderRotuloCor = () => {
    
    return <TonerLabelRotulo disabled={disabled} bgColor={colors[rotulo]}>{renderSigla(rotulo[0].toUpperCase())}</TonerLabelRotulo>;
  };

  // Renderiza a sigla do toner
  const renderSigla = (sigla) => {
    switch (sigla) {
      case 'C': return sigla;
      case 'M': return sigla;
      case 'A': return 'Y';
      case 'P': return 'K';
      default: return '';
    }
  };

  // Obtém a cor da barra de progresso com base no valor
  const getProgressBarColor = (value) => {
    if (value >= 60) return '#2E7D32'; // Verde
    if (value >= 25) return '#FFC107'; // Amarelo
    return '#F44336'; // Vermelho
  };

  return small ? (
    <Tooltip title={displayValue}>
      <ProgressBarContainer disabled={disabled}>
        {renderRotuloCor()}
        {/* Se disabled for false, mostra ProgressBarFiller e TonerLabelLateral */}
        {!disabled ? (
          <>
            <ProgressBarFiller
              style={{ width: `${value >= 0 && value <= 100 ? value : 0}%` }}
              color={usarCoresPadrao ? getProgressBarColor(value) : colors[rotulo]}
              disabled={disabled}
            />
            <TonerLabelLateral variant="caption" disabled={disabled}>
              {displayValue || '* indisponível'}
            </TonerLabelLateral>
          </>
        ) : (
          'n/d' // Caso o disabled seja true, mostra 'n/d'
        )}
      </ProgressBarContainer>
    </Tooltip>
  ) : (
    <div>
      <ProgressBarContainer disabled={disabled}>
        {renderRotuloCor()}
        {/* Se disabled for false, mostra ProgressBarFiller e TonerLabelLateral */}

        
           <React.Fragment><ProgressBarFiller
            style={{ width: `${value >= 0 && value <= 100 ? value : 0}%` }}
            color={usarCoresPadrao ? getProgressBarColor(value) : colors[rotulo]}
            disabled={disabled}
          />
          <TonerLabelLateral variant="caption" disabled={disabled}>
            {displayValue || '* indisponível'}
          </TonerLabelLateral></React.Fragment> 
        
       
        {alerta && nivelAlerta > value && (
          <Tooltip title={`Suprimento configurado para gerar alerta quando nível atingir ${nivelAlerta}%`}>
            <AlertIcon className="shake" disabled={disabled} />
          </Tooltip>
        )}
      </ProgressBarContainer>
    </div>
  );
};

// Validação das props
DisplayLinearToner.propTypes = {
  value: PropTypes.number,
  rotulo: PropTypes.oneOf(['ciano', 'magenta', 'amarelo', 'preto']),
  color: PropTypes.string,
  alerta: PropTypes.bool,
  nivelAlerta: PropTypes.number,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

// Props padrão
DisplayLinearToner.defaultProps = {
  value: 100,
  rotulo: 'preto',
  color: '#4caf50',
  alerta: false,
  nivelAlerta: 20,
  small: false,
  disabled: false,
};

export default DisplayLinearToner;
