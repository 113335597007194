import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container,  Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow,
Checkbox} from '@material-ui/core'
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs, FormControlLabel,Switch, Fab} from '@material-ui/core'
import {Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Cached';
import ConexaoInventory from  '../../providers/ConexaoInventory'
import Conexao from  '../../providers/Conexao'
import path from 'path';
import IconeDesktop from './imagens/iconeDesktop.png'
import IconeNote from './imagens/iconeNotebook.png'
import IconeVM from './imagens/iconeVirtualMachine.png'
import { FilterOutline } from 'react-ionicons'
import ModalFiltros from './SitebarFiltroInventory';
import ModallFull from '../../components/ModalFull';
import MapView from './Mapas/MapView';
import LocalizaIcon from '@material-ui/icons/Place'




const CadastroComputadoresInventory = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();
    const [inventorys, setInventorys]= useState([])
    const [inventorysFiltrados, setInventorysFiltrados]= useState([])
    const [mostrarInativos, setMostrarInativos] = useState(false)

    const [razaoSocial, setRazaoSocial]= useState('')
    const [modelo, setModelo] = useState('')
    const [enderecoIP, setEnderecoIP] = useState('')
    const [sistemaOperacional, setSistemaOperacional] = useState('')
    const [marca, setMarca] = useState('')
    const [chaveInstalacao, setChaveInstalacao] = useState('')
    const [showModal, setShowModal]= useState(false)
    const [diasPesquisa, setDiasPesquisa] = useState(3)



    const [pat, setPat]= useState('')
    const [bios, setBios]= useState('')


    const handleSliderChange = (event, newValue) => {
        setDiasPesquisa(newValue);
    };

    const renderTipo = (tipo) =>{
        switch(tipo){
            case 'Desktop': return <img src ={IconeDesktop} width={32} style={{marginTop :'5px'}}/>;
            case 'Notebook': return <img src ={IconeNote}  width={32} style={{marginTop :'5px'}}/>;
            case 'Virtual Machine': return <img src ={IconeVM}  width={32} style={{marginTop :'5px'}}/>;
            default:return <img src ={IconeDesktop} width={32}/>;
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(()=>{
        getDcas();
    }, [page])

    useEffect(()=>{
        setInventorysFiltrados(inventorys)
    }, [inventorys])


    const getDcas = () =>{
        
        setLoading(true)
        ConexaoInventory.get('InventoryController/getShortedAll?idusuario=1&idempresa='+props.location.state.empresa.idempresa+'&idcliente=1').then( response =>{
            if(response.data.status == 'OK'){
                for(let i = 0; i < response.data.payload.length; i++){
                    response.data.payload[i].diasDiferenca = calcularDiferenca(response.data.payload[i].dataAtualizacao )                    
                }
                if(mostrarInativos){
                    setInventorys(response.data.payload)
                }else{
                    setInventorys(response.data.payload.filter(item => item.coletaAtiva == 't'))
                }
               
               
            }else{
             
                setInventorys([])
            }
            setLoading(false)
        }).catch( error =>{
       
            setInventorys([])
            setLoading(false)

        });

    }


    const renderSignatureMobile = () =>{

    }


   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <PersonIcon color='primary' />
        }else{
            return <SettingsApplicationsIcon  color='primary'/>
        }
    }

    const renderData = (data) =>{
        var teste = data.split('T')
        return teste[0]+' '+teste[1].substr(0,8)
    }


    const searchEquipmento = (
        pChave = "",
        pRazaoSocial = "",
        pMarca = "",
        pModelo = "",
        pSistemaOperacional = "",
        pPatrimonio = "",
        pSerialNumber = "",
        pDias = null
    ) => {
        // Verifica se o array de registros existe e é válido
        if (!Array.isArray(inventorys)) {
            return [];
        }
    
        // Função para normalizar strings (letras minúsculas e sem espaços extras)
        const normalize = (value) => String(value || "").toLowerCase().trim();
    
        // Parâmetros normalizados
        const searchChave = normalize(pChave);
        const searchRazaoSocial = normalize(pRazaoSocial);
        const searchMarca = normalize(pMarca);
        const searchModelo = normalize(pModelo);
        const searchSistemaOperacional = normalize(pSistemaOperacional);
        const searchPatrimonio = normalize(pPatrimonio);
        const searchSerialNumber = normalize(pSerialNumber);
    
        // Filtra os registros com base nos critérios fornecidos
        const temp = inventorys.filter((dca) => {
            const dcaChaveInstalacao = normalize(dca._id);
            const dcaRazaoSocial = normalize(dca.razaoSocial);
            const dcaMarca = normalize(dca.Modelo?.Manufacturer);
            const dcaModelo = normalize(dca.Modelo?.Model);
            const dcaSistemaOperacional = normalize(dca.OperatingSystem?.Name);
            const dcaPatrimonio = normalize(dca.patrimonio);
            const dcaSerialNumber = normalize(dca.Bios?.SerialNumber);
            const dcaDias = parseInt(dca.diasDiferenca, 10); // Garante que é um número
    
            return (
                (!searchChave || dcaChaveInstalacao.includes(searchChave)) &&
                (!searchRazaoSocial || dcaRazaoSocial.includes(searchRazaoSocial)) &&
                (!searchMarca || dcaMarca.includes(searchMarca)) &&
                (!searchModelo || dcaModelo.includes(searchModelo)) &&
                (!searchSistemaOperacional || dcaSistemaOperacional.includes(searchSistemaOperacional)) &&
                (!searchPatrimonio || dcaPatrimonio.includes(searchPatrimonio)) &&
                (!searchSerialNumber || dcaSerialNumber.includes(searchSerialNumber)) &&
                (pDias === null || dcaDias <= pDias) // Aplica filtro de dias somente se definido
            );
        });
    
        // Atualiza a lista filtrada
        setInventorysFiltrados(temp);
    };
    
    
    
    
    
    


    const  calcularDiferenca = (dataEntrada, reduzido = false) => {
        // Converte a data de entrada para o objeto Date
        const data1 = new Date(dataEntrada);
        const dataAtual = new Date(); // Data atual    
        // Calcula a diferença em milissegundos
        const diffMilissegundos =  dataAtual - data1;    
        // Convertendo a diferença
        const diffSegundos = Math.floor(diffMilissegundos / 1000);           // Segundos
        const diffMinutos = Math.floor(diffSegundos / 60);                   // Minutos
        const diffHoras = Math.floor(diffMinutos / 60);                      // Horas
        const diffDias = Math.floor(diffHoras / 24);                       // Dias    
        // Calculando a diferença exata em cada unidade
        const diasRestantes = diffDias;
        const horasRestantes = diffHoras % 24;
        const minutosRestantes = diffMinutos % 60;
        const segundosRestantes = diffSegundos % 60;
    
        // Retorna o resultado como uma string formatada
     
        var ativo = true;
             
        return  diasRestantes+'-'+horasRestantes 
    }

    const  renderClasse = (dataEntrada) => {
        // Converte a data de entrada para o objeto Date
        const data1 = new Date(dataEntrada);

        const dataAtual = new Date(); // Data atual    
        // Calcula a diferença em milissegundos
        const diffMilissegundos =  dataAtual - data1;    
        // Convertendo a diferença
        const diffSegundos = Math.floor(diffMilissegundos / 1000); 
                  // Segundos
        const diffMinutos = Math.floor(diffSegundos / 60);    
                       // Minutos
        const diffHoras = Math.floor(diffMinutos / 60); 
                             // Horas
        const diffDias = Math.floor(diffHoras / 24);                        // Dias    
        // Calculando a diferença exata em cada unidade
        const diasRestantes = diffDias;

        const horasRestantes = diffHoras % 24;

        const minutosRestantes = diffMinutos % 60;

        const segundosRestantes = diffSegundos % 60;    
        // Retorna o resultado como uma string formatada
           
       // console.log("Data is not an array or is undefined.");
        if(diasRestantes > 5 ){

            return 'texto-limitado badge-error'

        }else if(horasRestantes > 1 && horasRestantes <=5){

            return 'texto-limitado badge-warning'
            
        } else{
            return 'texto-limitado badge-success'
        }   
    }

    const  calcularPosicaoMedia = (coordenadas) => {
        
    
        let somaLat = 0;
        let somaLng = 0;
    
        coordenadas.Posicao.forEach(coordenada => {
            if(coordenada.latitude){
                coordenada.latitude = coordenada.latitude.relpace(',','.')
            }

            if(coordenada.longitude){
                coordenada.longitude = coordenada.longitude.relpace(',','.')
            }
            somaLat += coordenada.latitude;
            somaLng += coordenada.longitude;
        });
    
        const mediaLat = somaLat / coordenadas.length;
        const mediaLng = somaLng / coordenadas.length;
    
        return { lat: mediaLat, lng: mediaLng };
    }
    
    // Exemplo de uso
    const listaDeCoordenadas = [
        { lat: -23.55052, lng: -46.633308 },
        { lat: -22.903539, lng: -43.209587 },
        { lat: -25.428954, lng: -49.267137 }
    ];
    
   
    
    
    return <Principal atualizar = {loading} getData={getDcas} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Inventory Computadores
            </Link>                
        </Breadcrumbs>
    }>
      
        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}></Grid>
                <Grid item xs = {5}>                    
                </Grid>
            </Grid>
        </div>        
      
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'20px'}}>
           <Grid item xs = {10}>
           <FormControlLabel 
                control={<Checkbox checked = {mostrarInativos} onChange={(e)=>setMostrarInativos(!mostrarInativos)}/>} label="Mostrar desabilitados"/>
           </Grid>
           <Grid item xs = {2}>
                <Button onClick={()=>setShowModal(!showModal)}>
                        <LocalizaIcon/> <Typography variant='caption'>Ver Mapa</Typography>
                </Button>
            </Grid>
        </Grid>
        
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'10px'}}>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <ModalFiltros 
                diasPesquisa={diasPesquisa}
                handleSliderChange={handleSliderChange}
                marca = {marca}
                setMarca = {setMarca}
                modelo = {modelo}
                setModelo = {setModelo} 
                razaoSocial = {razaoSocial}
                setRazaoSocial = {setRazaoSocial}
                sistemaOperacional ={sistemaOperacional}
                setSistemaOperacional = {setSistemaOperacional}
                chaveInstalacao = {chaveInstalacao}
                setChaveInstalacao ={setChaveInstalacao}
                enderecoIP ={enderecoIP}
                setEnderecoIP ={setEnderecoIP}
                pat = {pat}
                setPat = {setPat}
                bios = {bios}
                setBios = {setBios}
                serchData = {(dias)=>{
                    searchEquipmento(chaveInstalacao,  razaoSocial, marca, modelo, sistemaOperacional, pat, bios, diasPesquisa)                                      
                }}

                resetFilter ={()=>{                    
                    setPat('')
                    setModelo('')
                    setMarca('')
                    setRazaoSocial('')
                    setSistemaOperacional('')
                    setChaveInstalacao('')
                    setEnderecoIP('')
                    setBios('')  
                    setDiasPesquisa(3)              
                    searchEquipmento('',  '', '', '', '', '', '', '')
                }}
            />
             
        </Grid>

        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>{colunaFiltro.toString().toUpperCase()}</Typography></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                value={razaoSocial}
                onChange={(e)=>setRazaoSocial(e.target.value)}
               
            />
        </Grid>

        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{                              
                getDcas()                 
            }}><SearchIcon/></Button></Tooltip>
        </Grid>

        </Grid>
        <Typography style={{fontSize : '12px', color:diasPesquisa == 0 ? 'green' : 'red'}} variant='caption'>{diasPesquisa == 0 ? 'Última comunicação hoje' : 'Última comunicação há ' + diasPesquisa+' dia(s)'}</Typography>
       <div className='div-form-container'>
        <Table className='table table-hover table-sm table-small table-hovered'>
            <TableHead>
                <TableCell align='center' style={{width :'6%'}}>#</TableCell>
                <TableCell style={{width :'18%'}}>Equipamento</TableCell>
                <TableCell style={{width :'27%'}}>Cliente</TableCell>
                <TableCell style={{width :'15%'}}>Sistema Operacional</TableCell>    
                <TableCell align='center' style={{width :'12%'}}>Data Coleta</TableCell>
                <TableCell align='center' style={{width :'8%'}}>STATUS</TableCell>
            </TableHead>
            <TableBody>

            {

                    inventorysFiltrados ? inventorysFiltrados.map(function(inventory, i){                                           
                        return 1 == 1 ? <TableRow  >
                                <TableCell align='center' >
                                {
                                        !inventory.coletaAtiva || inventory.coletaAtiva =='t' ? 
                                    
                                    <React.Fragment>
                                        <Link to={{pathname : '/DataInventoryDetails', state : {
                                            empresa : props.location.state.empresa,
                                            usuario : props.location.state.usuario,  
                                            idcliente : inventory.idcliente, 
                                            inventory :inventory, 
                                            dias : inventory.diasDiferenca 
                                        }}}> <div style={{fontSize :'10px'}} className={renderClasse(inventory.dataAtualizacao)}>
                                        <div>{inventory.hostName ? inventory.hostName : '* não disponível'}</div>
                                            {
                                                renderTipo(inventory.typeDevice)
                                            }
                                        </div> 
                                        </Link>
                                    </React.Fragment>
                                    :<React.Fragment>
                                        <div style={{opacity : 0.5}} onClick={()=>{
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text : 'Este DCA não esta ativado, ative antes de acessar!', 
                                                showConfirmButton: false, 
                                                showCancelButton : false,
                                                timer :2000,
                                                icon : 'question'
                                            })
                                        }}>
                                            <div style ={{fontSize : '10px'}}>{inventory.hostName ? inventory.hostName : '* não disponível'}</div>
                                            {
                                                renderTipo(inventory.typeDevice)
                                            }
                                        </div> 
                                    </React.Fragment> 
                                }
                                                                                                                                                                                               
                                </TableCell>
                                <TableCell style ={{verticalAlign : 'middle'}}>
                                  
                                           
                                            <div>
                                                <Typography variant='subtitle2' >
                                                { inventory.modeloAlternativo ? inventory.modeloAlternativo : (inventory.Modelo ? inventory.Modelo.Model : '* não disponível')}
                                                </Typography>                                    
                                            </div>
                                            <div>
                                                <Typography variant='caption' style={{fontSize :'10px'}}>
                                                    PAT:{inventory.patrimonio  ? <span>{inventory.patrimonio}</span> : '* não disponível'}
                                                </Typography>                                    
                                            </div>                                  
                                    
                                        <Typography variant='caption' > S/N: <strong>{inventory.Bios ? inventory.Bios.SerialNumber : '* não disponível'}</strong></Typography>
                                </TableCell>
                                <TableCell style ={{verticalAlign : 'middle'}}>
                                    <Typography variant='subtitle1'>{inventory.razaoSocial ? inventory.razaoSocial : '* não disponível'}</Typography>
                                    <Typography variant='caption' > Chave DCA: <strong>{inventory.chaveDca}</strong></Typography>
                                </TableCell>
                                <TableCell style ={{verticalAlign : 'middle'}}>
                                    <div>
                                    <Typography variant='caption'>{inventory.OperatingSystem.Name ? inventory.OperatingSystem.Name : '* não disponível'}</Typography>
                                    </div>                                    
                                </TableCell>
                                                      
                                <TableCell align='center' style ={{verticalAlign : 'middle'}}>{inventory.dataAtualizacao ? renderData(inventory.dataAtualizacao) : '* não disponível'}</TableCell>
                                <TableCell align='center' style ={{verticalAlign : 'middle'}}>
                                    {
                                        inventory.coletaAtiva == 't' 
                                        ? 
                                        <span style={{
                                                     border : 'solid 1px #009688', 
                                                     color:"#009688", 
                                                     backgroundColor : "#fff", 
                                                     paddingLeft : '5px', 
                                                     paddingRight : '5px', 
                                                     borderRadius : '5px', 
                                                     cursor :'pointer'}} onClick={()=>{                                                        
                                                        Swal.fire({
                                                            title : 'Datacount Inventory', 
                                                            text : 'Deseja DESATIVAR a coleta do módulo Inventory?', 
                                                            icon:'question', 
                                                            showCancelButton:true, 
                                                            cancelButtonText : 'Não', 
                                                            showConfirmButton : true, 
                                                            confirmButtonText : 'SIM'
                                                        }).then(resposta =>{
                                                            if(resposta.isConfirmed){
                                                                Conexao.post('dcaController/ativarStatusDcaInventory', {
                                                                    chaveDca : inventory.chaveDca, 
                                                                    statusDca : 'f'                                                             
                                                                }).then(response=>{
                                                                    if(response.data.status == 'OK'){
                                                                        ConexaoInventory.post('InventoryController/updateOneInterfaceChave?idusuario=1&idempresa='+props.location.state.empresa.idempresa+'&idcliente=1', {
                                                                            chaveDca : inventory.chaveDca, 
                                                                            coletaAtiva : 'f'
                                                                        }).then( response =>{
                                                                            if(response.data.status == 'OK'){                                                                                
                                                                                getDcas()
                                                                            }                                                                            
                                                                        }).catch( error =>{                                                                       
                                                                
                                                                        });                                                                       
                                                                    }
                                                                }).catch(error=>{

                                                                })
                                                            }
                                                        })
                                                     }}>HABILITADO</span>
                                        :
                                        <span style={{
                                                     border : 'solid 1px #E53935', 
                                                     color:"#E53935", 
                                                     backgroundColor : "#fff", 
                                                     paddingLeft : '5px', 
                                                     paddingRight : '5px', 
                                                     borderRadius : '5px', 
                                                     cursor :'pointer'}} onClick={()=>{
                                                        Swal.fire({
                                                            title : 'Datacount Inventory', 
                                                            text : 'Deseja ATIVAR a coleta do módulo Inventory?', 
                                                            icon:'question', 
                                                            showCancelButton:true, 
                                                            cancelButtonText : 'Não', 
                                                            showConfirmButton : true, 
                                                            confirmButtonText : 'SIM'
                                                        }).then(resposta =>{
                                                            if(resposta.isConfirmed){
                                                                Conexao.post('dcaController/ativarStatusDcaInventory', {
                                                                    chaveDca : inventory.chaveDca, 
                                                                    statusDca : 't'                                                             
                                                                }).then(response=>{
                                                                    if(response.data.status == 'OK'){
                                                                        ConexaoInventory.post('InventoryController/updateOneInterfaceChave?idusuario=1&idempresa='+props.location.state.empresa.idempresa+'&idcliente=1', {
                                                                            chaveDca : inventory.chaveDca, 
                                                                            coletaAtiva : 't'
                                                                        }).then( response =>{
                                                                            if(response.data.status == 'OK'){                                                                                
                                                                                getDcas()
                                                                            }
                                                                        }).catch( error =>{                                                                       
                                                                
                                                                        });                                                                       
                                                                    }
                                                                }).catch(error=>{

                                                                })                                                                
                                                            }
                                                        })
                                                     }}>DESABILITADO</span>
                                    }       

                                </TableCell>                               
                    </TableRow> : null
                    }) : null
                }
            </TableBody>
        </Table>
        
        </div>                  
       
        <ModallFull setShowModal={()=>setShowModal(!showModal)} showModal={showModal}>
            <MapView positions = {inventorysFiltrados.filter(item => item.coletaAtiva == 't')} />
        </ModallFull>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroComputadoresInventory)