import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  position: relative;
  width: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto; /* Permite rolagem horizontal */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; /* Força a rolagem horizontal */
`;

const TableHeader = styled.thead`
  background-color: #333;
  color: white;
`;

const TableFooter = styled.tfoot`
  background-color: #333;
  color: white;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
`;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
`;

const FixedTable = () => {
  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <TableHeader>
            <tr>
              <TableHeaderCell>Título 1</TableHeaderCell>
              <TableHeaderCell>Título 2</TableHeaderCell>
              <TableHeaderCell>Título 3</TableHeaderCell>
              <TableHeaderCell>Título 4</TableHeaderCell>
              <TableHeaderCell>Título 5</TableHeaderCell>
              <TableHeaderCell>Título 6</TableHeaderCell>
              <TableHeaderCell>Título 7</TableHeaderCell>
              <TableHeaderCell>Título 8</TableHeaderCell>
            </tr>
          </TableHeader>
          <tbody>
            <TableRow>
              <TableCell>Conteúdo 1</TableCell>
              <TableCell>Conteúdo 2</TableCell>
              <TableCell>Conteúdo 3</TableCell>
              <TableCell>Conteúdo 4</TableCell>
              <TableCell>Conteúdo 5</TableCell>
              <TableCell>Conteúdo 6</TableCell>
              <TableCell>Conteúdo 7</TableCell>
              <TableCell>Conteúdo 8</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Conteúdo 9</TableCell>
              <TableCell>Conteúdo 10</TableCell>
              <TableCell>Conteúdo 11</TableCell>
              <TableCell>Conteúdo 12</TableCell>
              <TableCell>Conteúdo 13</TableCell>
              <TableCell>Conteúdo 14</TableCell>
              <TableCell>Conteúdo 15</TableCell>
              <TableCell>Conteúdo 16</TableCell>
            </TableRow>
            {/* Adicione mais linhas conforme necessário */}
          </tbody>
          <TableFooter>
            <tr>
              <TableCell>Rodapé 1</TableCell>
              <TableCell>Rodapé 2</TableCell>
              <TableCell>Rodapé 3</TableCell>
              <TableCell>Rodapé 4</TableCell>
              <TableCell>Rodapé 5</TableCell>
              <TableCell>Rodapé 6</TableCell>
              <TableCell>Rodapé 7</TableCell>
              <TableCell>Rodapé 8</TableCell>
            </tr>
          </TableFooter>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default FixedTable;
